.mobile-version{
    display:none;
}
@media (max-width: 600px) {
    .mobile-version{
        display:block;
        
    }

    .PC-version{
        display:none;
    }
  }