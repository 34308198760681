.row-cf{
    margin-top:3vw;
}

.cf-main{
    font-family: 'M Plus 1p';
    margin-top:2vw;
    color: black;
    font-size: 1.5vw;
    margin-bottom:2vw;
    // background-color: #f1f1f1;
    padding: 2vw;
    width: 100%;
    border: 2px solid black;
}
.cf-main label{
    font-size: 14px;
}
.cf-main input{
    margin-right: 1vw;
  accent-color: #007bff;
  width: 1.3vw;
  height: 1.3vw;
  
}
.box-comple{
    margin-top: 4vw;
    padding: 3vw;
    border: 2px solid black;
    width: 60%;
    height: auto;
}
.img-done{
    width: 50px;
    margin-bottom: 3vw;
}
@media (max-width: 768px) {
    .box-comple{
       
        width: 97%;
        
    }
    .contain-main{
        width: 100%;
    }
    .cf-main input {
        height: 3vw;
        width: 3vw;
    }
    .sp-btn{
        width: 90% !important;
    }
    .title-sp{
        font-size: 24px !important;
    }
}