.table {
  &--custom {
    width: 1200px;

    @include media-breakpoint-up(xl) {
      width: 100%;
    }

    thead th,
    thead td {
      background-color: rgba($white, 0.3);
    }

    th,
    td {
      @include rem(
        (
          padding: 6px 20px,
        )
      );
    }
  }

  td {
    color: black;
    font-size: 1.5vw;
  }

  .table-td_icon {
    font-size: 24px;
  }
}

.table-custom {
  td {
    vertical-align: middle;
  }
  thead th {
    vertical-align: middle !important;
  }
}

.table-sm {
  th,td {
    padding: 0.75rem 0.35rem;
  }
}

@media only screen and (max-width: 769px) {
  .table {
    .table-td_icon {
      font-size: 2.5vw;
    }
  }
} 
