@media only screen and (max-width: 769px) {
  .btn-custom-sm {
    // height: 42px !important;
    // font-size: 22px !important;
  }

  .input-custom-sm {
    width: 100%;
    height: 42px;
  }
}

.btn-custom-sm {
  width: 100% !important;
  height: 50px !important;
  font-size: 22px !important;
}

.btn-cancel {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  border-radius: 5px;

  font-weight: bold;
  font-size: 14px;
  float: left;
  margin-top: 0 !important;
  color: black !important;
}

#fileInput {
  display: none;
}

/* Custom button styling */
.custom-file-label {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
