.modal {
  &-content {
    background-color: transparent;
    border: none;
    border-radius: 0;
  }

  &-header {
    border-bottom: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-left: 0;
    padding-right: 0;

    .close {
      font-weight: 500;
      line-height: (16/24);
      text-shadow: none;
      border-radius: 5px;
      background-color: $white;
      padding: 0;
      margin: 0 0 0 auto;
      opacity: 1;
      outline: none;

      @include rem(
        (
          font-size: 24px,
          width: 27px,
          height: 27px,
        )
      );
    }
  }

  &-body {
    padding: 0;
  }
}
