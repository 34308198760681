.pagination-control {
  &__prev,
  &__next,
  &__page select {
    border-radius: 1.5px !important;
  }

  &__prev,
  &__next {
    display: flex;
    width: 32px;
    height: 32px;

    i {
      margin: auto;
    }
  }

  &__page {
    select {
      height: 32px;

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.pagination {
  .page-item {
    width: 32px;
    height: 32px;
    text-align: center;
    margin-left: 10px;

    &.disabled {
      .page-link {
        color: $salon-divider-2 !important;
        border-color: transparent;
        background-color: transparent;
      }
    }
  }

  .page-link {
    display: block;
    padding: 0;
    height: 100%;
    line-height: 30px;
    color: $black !important;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: $border-radius-xl !important;
    cursor: pointer !important;

    // background-color: rgba($salon-green, 0.1);  
    box-shadow: 1px 1px 4px rgba($black, 0.5);

    &:hover:not(.page-dot) {
      background-color: rgba($salon-green, 0.4);
      box-shadow: 1px 1px 4px rgba($black, 0.5);
      border-color: $salon-dark;
    }

    &.active-page {
      background-color: $salon-green !important;
      color: $white !important;
      border-color: $salon-dark;
      font-weight: $font-weight-bold;
      box-shadow: none !important;
    }

    &.page-dot {
      pointer-events: none;
      cursor: default !important;
    }
  }

  &--dark-mode {
    .page-item.disabled .page-link,
    .page-link {
      color: $white;
      background-color: $salon-color-03;
      border-color: $salon-color-03;

      &:hover {
        color: $white;
        background-color: $salon-color-admin-block-title;
        border-color: $salon-color-admin-block-title;
      }
    }
  }
}
