@import '../variables';
.tab-container {
    .tab-link h5 {
        font-size: 16px;
        margin-right: 10px !important ;
        
    }
}
.tab-user.active {
    background-color: #d9ead3;
    color: #719f5d;
}

.tab-user.current-active {
    background-color: #d9ead3;
    color: black;
}

.tab-user h5 {
    font-size: 1.5em;
}

.tab-user-description {
    font-size: 16px;
    letter-spacing: 0.2em;
}

.tab-link {
    letter-spacing: 2px;
}
@media only screen and (min-width: 769px) and (max-width: 1200px) {
    .tab-container {
        .tab-link h5 {
            // font-size: 2vw !important;
            // margin-right: 0.5vw !important;
        }
    }
}




@media only screen and (max-width: 769px) {
    .tab-container {
        

        .tab-link {
            flex: 1;
            height: auto;
            min-height: 70px;
            letter-spacing: 0.125vw !important;

            .tab-user {
                padding: 0.75vw 1.5vw !important;
            }

            h5 {

                font-size: $font-secondary;
                margin-right: 1vw !important;
            }


        }
        
    }
    .tab-user-description {
        font-size: $font-secondary !important;
    }


}


