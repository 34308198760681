.card-custom-ms {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 0.5rem;
    margin-bottom: 20px;

    &:hover {
        transform: scale(1.02);
        box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 20px;
    }

    .card-body {
        padding: 0;

        .card-content {
            display: flex;
            flex-direction: column;

            .card-list {
                display: flex;
                flex-direction: row;
                // justify-content: space-between;
                align-items: start;
                padding: 10px 20px;
                border-bottom: 1px solid #f2f2f2;

                &:last-child {
                    // border-bottom: none;
                }

                .card-title {
                    font-size: 16px;
                    margin: 3px 0 0 0;
                    width: 30%;
                    min-width: 130px;
                    text-align: start;
                }

                .card-text {

                    font-size: 16px;
                    margin: 0;
                    word-wrap: break-word
                }
            }

            .card-list-edit {
                width: 100%;
                align-items: center;


                .card-title-icon {
                    margin: 0;
                    width: 30%;
                    min-width: 130px;
                }

                .card-list-icon {
                    display: flex;
                    justify-content: flex-start;
                    padding: 0px 0;


                    .card-icon {
                        font-size: 24px;

                        margin-right: 15px;


                    }
                }
            }

            .card-toggle {
                padding: 10px;

                .btn-card-toggle {

                    background-color: #fff;
                    border: 1px solid #32b16c;
                    border-radius: 8px;
                    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
                    box-sizing: border-box;
                    color: #0f1111;
                    cursor: pointer;
                    display: inline-block;
                    font-size: 13px;
                    position: relative;
                    text-align: center;
                    text-decoration: none;
                    user-select: none;
                    -webkit-user-select: none;
                    touch-action: manipulation;
                    vertical-align: middle;
                    width: 100%;
                    font-size: 22px;
                    font-weight: 700;


                    .btn-card-toggle:hover {
                        background-color: #f7fafa;
                    }

                    .btn-card-toggle:focus {
                        border-color: #008296;
                        box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
                        outline: 0;
                    }


                }

            }

        }
    }
}