.row-btn{
    display: flex;
    justify-content: space-between;
    width: 50vw;
}

.btnx{
    width: 20vw;
    font-size: 2vw;
}
.btn-cancel{
    border: 1px solid #ccc;
    color:black;
}
.content-main{
    margin-top:2vw;
    color: black;
    font-size: 1.5vw;
    margin-bottom:2vw;
    background-color: #f1f1f1;
    padding: 2vw;
    width: 50vw;
}
.contain-main{
    padding: 2vw;
    width: 100%;
    background-color: white;
}

.bg{
   
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f1f1f1;
    padding: 2vw;
}