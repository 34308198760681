.bx-authentication {
  width: 100%;
  padding-top: $header-h;
  overflow: hidden;
  z-index: 1001;

  @include fixed(0, 0, 0);
  @include vhidden();
  @include transition(all 0.5s ease);

  &::before {
    content: '';

    @include overlay();

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &.open {
    right: 0;

    @include vdisplay();

    .authentication-form {
      margin-right: 0;
    }
  }

  &__close {
    width: 42px;
    height: 42px;
    color: $salon-text-placeholder;
    background-color: rgba($salon-primary, 0.1);
    cursor: pointer;

    @include transition(all 0.25s ease);

    &:hover {
      color: $salon-primary;
      background-color: $salon-primary;
    }

    @include media-breakpoint-up(lg) {
      width: auto;
      height: auto;
      cursor: pointer;
      color: $salon-text-placeholder;
      background-color: transparent !important;

      @include rem(
        (
          font-size: 16px,
          margin-top: -30px,
          margin-right: -10px,
        )
      );
      @include transition(all 0.25s ease);

      &:hover {
        color: $salon-primary;
      }
    }

    @include media-breakpoint-down(md) {
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 0 !important;
      background: transparent !important;
    }
  }

  form {
    .form-text {
      color: $salon-text-secondary;
    }
  }

  .authentication-form {
    position: relative;
    z-index: 10;
    background-color: $salon-bg-2;
    box-shadow: -4px 4px 20px rgba($black, 0.1);
    max-width: 375px;
    margin-left: auto;
    margin-right: -100%;
    overflow: auto;
    overflow-x: hidden;

    @include transition(all 0.5s ease);

    a {
      color: $salon-primary;
      cursor: pointer;

      &:hover {
        color: lighten($salon-primary, 20%);
      }
    }

    @include media-breakpoint-down(sm) {
      max-width: 90%;
      border-radius: 0;
    }
  }
}

#screen-forgot-password {
  background: $white;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  position: fixed;
  width: auto;
}

#form-change-password {
  @include media-breakpoint-up(lg) {
    width: 60%;
  }
}

// Update mobile UI version
.mobile-app {
  .register-form {
    max-width: 100%;
    box-shadow: none;
  }
  .tab-pane {
    overflow: hidden;
    .policy-content {
      height: 50vh;
      overflow-y: auto;
    }
  }
}
