@import '../variables';

.user-confirm-container {
    h1,h2 {
        font-size: 16px;
    }
    p,h3,h4 {
        font-size: 14px;
        margin: 0;
    }
    button {
        height: $btn-h-primary !important;
    }
}


@media (max-width: 769px) {
    .btn-user-confirm {
        justify-content: center !important;
        width: 100% !important;
    }
}