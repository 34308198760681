.table-reservation {
    td {
        word-wrap: break-word;
        padding: 2px !important;
    }

    th {

        vertical-align: middle !important;
        text-align: center;
        padding: 2px !important;
    }

    thead {
        tr {
            th {
                font-size: 16px;
            }
        }
    }
    tbody {
        td {
            font-size: 16px;
        }
    }
}

.delete-reservation {
    .card {
        .card-body {
            h5 {
                margin: 0;
                margin-top:1px;
            }
            p {
                align-self: flex-start;
                margin: 0;
            }
        }
    }
}

.font-24 {
    font-size: 24px;
}

.font-20 {
    font-size: 22px;
}
.font-16 {
    font-size: 16px;
}

@media only screen and (max-width: 400px) {
    .font-24 {
        font-size: 1.5vw;
    }

    .font-20 {
        font-size: 1vw;
    }
    .font-16 {
        font-size: 0.75vw;
    }
}

@media only screen and (min-width: 400px) and (max-width: 575.98px) {
    .font-24 {
        font-size: 2.5vw !important;
    }

    .font-20 {
        font-size: 2vw !important;
    }
    .font-16 {
        font-size: 1vw !important;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767.98px) {
    .font-24 {
        font-size: 2.4vw !important;
    }

    .font-20 {
        font-size: 2vw !important;
    }
    .font-16 {
        font-size: 2vw !important;
    }

    
}

@media only screen and (max-width: 768px)  {
    .table-reservation {
        
    
        thead {
            tr {
                th {
                    font-size: 2vw;
                }
            }
        }

        tbody {
            td {
                font-size: 2vw;
            }
        }
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    /* Quy tắc CSS cho các máy tính để bàn và thiết bị tương tự */
}

@media only screen and (min-width: 1200px) {}