input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  /* Firefox */
}

.input-slot:focus {
  border: none;
  outline: none;
}

.btn:focus {
  box-shadow: none;
}

.react-calendar {
  position: absolute;
  top: 70px;
  background: white;
  width: 100%;
  max-width: 700px;
  z-index: 1;
}

.react-calendar__navigation__label,
.react-calendar__navigation__arrow,
.react-calendar__month-view {
  color: black;
}

.react-calendar__month-view__weekdays {
  text-transform: none;

  abbr {
    text-decoration: none;
  }
}

.react-calendar__month-view__days__day {
  color: black;

  &--weekend {
    color: red;
  }

  &--neighboringMonth {
    opacity: 0.4;
    color: black;
    background-color: #ccc;
    border-radius: 0px !important;
  }
}

.react-calendar__tile {
  font-size: 1.3em;
  padding: 40px 0px;
  border-radius: 50%;

  &--active {
    background-color: #fff;
    color: #476ec7;
  }

  &--now {
    background-color: #bdbd82;
  }
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: #fff;
}

button {
  outline: none !important;
}

.even-row {
  background-color: #f2f2f2;
}

.odd-row {
  background-color: #fff;
}

.head-table {
  background-color: #5cb85c;
}

// td {
//     width: 50%;
// }

.table-case {
  width: 100%;
  max-width: 700px;
}

.input-slot {
  width: 100%;
  border: none;
  background-color: none;
}

.arrow-calender {
  max-width: 700px;
}
.title-date-calender,
.previous-day-title,
.next-day-title {
  font-size: 18px;
  font-weight: bold;
}
@media only screen and (max-width: 400px) {
  .react-calendar__tile {
    font-size: 1em;
    padding: 4.5vw 0 !important;
  }
}

@media only screen and (min-width: 400px) and (max-width: 575.98px) {
  .react-calendar__tile {
    font-size: 1em;
    padding: 5vw 0 !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767.98px) {
  .react-calendar__tile {
    font-size: 1em;
    padding: 5.5vw 0 !important;
  }
}

@media only screen and (max-width: 769px) {
  // .title-date-calender,.previous-day-title ,.next-day-title{
  //     font-size: 4vw;
  // }
  // .previous-day i,.next-day i {
  //     font-size: 3vw;
  // }
}

.calendar-reservationmanagement {
  .react-calendar__navigation__next2-button,
  .react-calendar__navigation__prev2-button {
    display: none;
  }
  .react-calendar__tile--now {
    background-color: white;
  }
  .react-calendar__tile--active {
    background-color: rgba(118, 204, 150, 0.8);
  }
  .react-calendar__tile {
    border-radius: 10px;
    position: relative;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: rgba(118, 204, 150, 0.8);
  }
}
