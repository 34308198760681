.inspection-certificate {
  table {
    tr {
      position: relative;
    }
  }

  &__detail {
    position: absolute;
    right: 0;
    left: 0;
    background-color: $salon-dark;
    // max-height: 350px;
    overflow: hidden;
    overflow-y: auto;

    @include rem(
      (
        padding: 0 20px,
        margin-top: 20px,
      )
    );
    @include vhidden();
    @include transition(all .25s ease);

    &.open {
      @include vdisplay();
    }
  }

  &__wrap {
    background-color: rgba($white, 0.05);

    @include rem(
      (
        padding: 30px,
      )
    );
  }
}
