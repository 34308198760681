@import '../variables';

.sp-txt {
  display: none !important;

}

.schedule {
  .date-week {
    table {
      width: 100%;
      border-collapse: collapse;

      th,
      td {
        border: 2px solid #c1c1c1;
        border-left: none;
        padding: 10px 14px;
        text-align: center;
        max-width: 30px;
        background-color: #ffffff;
      }

      th {
        background-color: #fff;

        &.today {
          // background-color: #e9ecef;
          font-weight: bold;
        }

        &.saturday {
          // background-color: #d9e3ec;
          color: #0e6eb2;
        }

        &.sunday {
          // background-color: #eeddd9;
          color: #ee4b4b;
        }

      }


      .booked {
        background-color: #f1f7fe;
        pointer-events: none;
      }


      .available {
        background-color: #ffffff;
        cursor: pointer;
      }
    }
  }

  .navigation {
    .date-week {
      .month-year {
        // border-bottom: 1px solid black;
      }

      button {
        min-height: 70px;
        min-width: 80px;


        padding: 0;
        font-weight: bold;
        border: 1px solid;
      }

      .btn-week {
        font-size: 16px;


        &.đefault {
          opacity: 0.5;
          pointer-events: none;
        }

        &.next-week {
          border-left: none;
        }
      }
    }

    .schedule-select {
      display: flex;
      width: 100%;

      .time-day {
        display: flex;
        flex-direction: column;
        min-width: 98px;

        .time-slot {
          display: flex;
          flex-direction: column;
          justify-content: center;
          border: 2px solid #c1c1c1;
          border-top: none;
          padding: 5px 14px;
          text-align: center;
          height: 50px;
        }

        &.time-day-right {
          .time-slot {
            border-left: none;
          }
        }
      }

      .table-data {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: space-between;

        table {
          width: 100%;
          border-collapse: collapse;

          td {
            border-right: 2px solid #c1c1c1;
            border-bottom: 2px solid #c1c1c1;
            padding: 10px;
            text-align: center;
            height: 49px;
            max-width: 30px;

            &.booked {
              background-color: #f1f7fe;
              pointer-events: none;
            }

            &.available {
              background-color: #ffffff;
              color: red;
              cursor: pointer;
            }

            &.temp-booked {
              color: red;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .date-week {

    button {
      
      border: 2px solid #c1c1c1 !important;
    }

    .btn-week {
    

      &.next-week {
        border-left: none !important;
      }
    }
  }
}
@media only screen and (min-width: 769px) and (max-width: 1200px) {

  .date-week {

    button {
      
      border: 2px solid #c1c1c1 !important;
    }

    .btn-week {
    

      &.next-week {
        border-left: none !important;
      }
    }
  }
  .schedule-select {


    .time-day {

      min-width: 80px !important;
    }

    .date-week {
      table {


        th,
        td {

          padding: 10px 20px;

        }



      }
    }
  }

  .date-week {
    table {
      th,
      td {
        padding: 7px 8px !important;

      }

    }
  }

  .table-data {
    table {
      td {
        padding: 5px 8px !important;

      }
    }
  }
}

@media  (min-width: 900px) {
  .date-week {
    table {
      th,
      td {
        padding: 7px 13px !important;

      }

    }
  }

  .table-data {
    table {
      td {
        padding: 5px 13px !important;

      }
    }
  }
}

@media (max-width: 769px) {

  .schedule-select {
    border-top: 1px solid black;
    margin-top: -3px;

    .time-day {
      // margin-top: 1.5px;
      min-width: 80px !important;
    }

  }

  .sp-view {
    display: none !important;
  }

  .sp-txt {
    display: block !important;
    position: absolute;
    top: -55px;
    float: right;
  }

  .user-schedule-container {
    p {
      font-size: $font-tertiary !important;
    }

    h3,

    h5 {
      font-size: $font-secondary !important;
    }

    // button {
    //   font-size: $font-secondary !important;
    // }

    table {
      font-size: $font-secondary !important;
    }
  }

  .schedule {
    .date-week {
      table {

        th,
        td {
          padding: 7px 24px;
          max-width: 20px;
          border-bottom: none !important;


        }

      }

      button {
        min-height: 50px;
        min-width: 150px !important;


      }

      .month-year {
        font-size: $font-title !important;
      }
    }

    .direction-pm span {
      text-decoration: underline solid #007bff;

    }

    .navigation {
      margin-top: 130px;
      position: relative;

      .schedule-select {
        .time-day {
          border-top: 2px solid #c1c1c1;

          .time-slot {
            // padding: 2px;
            // height: 30px;
          }
        }

        .table-data {
          table {
            border-top: 2px solid #c1c1c1;

            td {
              padding: 5px 24px;
              height: 30px;
              max-width: 20px;
            }
          }
        }
      }

      .date-week {


        // margin-bottom: 80px;
        .btn-week {
          min-height: 50px !important;

        }

        .next-week {
          top: -115px;
          right: 0px;
   

          i {
            margin-left: 10px;
          }

        }

        .prev-week {
          top: -115px;
          left: 0px;

          i {
            margin-right: 10px;
          }
        }


      }
    }
  }

  .btn-week {
    border: 1px solid  !important;
    border-radius: 0.25rem !important;
  }

  .btn-week-bottom {
    font-size: 16px;
    min-height: 50px;
    min-width: 150px !important;
    border: 1px solid;

  }

  .prev-week-bottom {
    margin-left: 0px;
  }

  .next-week-bottom {
    margin-right: 0px;
  }

  .title-boardInfor {
    padding: 0 !important;
    font-size: $font-secondary !important;
  }

  .board-infor-option {
    flex-direction: column;
    padding: 0 !important;
    font-size: $font-tertiary !important;

    p {
      margin: 0 !important;
    }

    .board-name-option {
      width: 100% !important;
    }

    .board-time-price {
      flex-direction: column !important;
      justify-content: flex-start !important;
      width: 100% !important;
    }
  }
}


@media (max-width: 650px) {
  .date-week {
    table {

      th,
      td {
        padding: 7px 18px !important;



      }

    }
  }

  .table-data {
    table {


      td {
        padding: 5px 18px !important;

      }
    }
  }
}


@media (max-width: 550px) {
  .date-week {
    table {

      th,
      td {
        padding: 7px 10px !important;



      }

    }
  }

  .table-data {
    table {


      td {
        padding: 5px 10px !important;

      }
    }
  }
}

@media (max-width: 450px) {
  .date-week {
    table {

      th,
      td {
        padding: 7px 4px !important;



      }

    }
  }

  .table-data {
    table {


      td {
        padding: 5px 4px !important;

      }
    }
  }
}