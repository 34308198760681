//
// Color system
//

$black: #000000;
$white: #ffffff;

// Salon Reservation
$salon-green: #32b16c;
$salon-red: #b13241;
$salon-blue: #315897;
$salon-dark: #1a1a1a;
$salon-gray: #434343;
$salon-gray-light: #9d9b9b;

$arr-colors-name: (
  'salon-green': $salon-green,
  'salon-red': $salon-red,
  'salon-blue': $salon-blue,
  'salon-dark': $salon-dark,
  'salon-gray': $salon-gray,
  'salon-gray-light': $salon-gray-light,
);
$arr-colors-name: map-merge((), $arr-colors-name);

$success: $salon-green;
$danger: $salon-red;

$theme-colors: ();
$theme-colors: map-merge((), $theme-colors);

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-bg-level: 0;
$alert-border-level: 0;
$alert-color-level: 10;

// Body
//
// Settings for the `<body>` element.

$body-bg: $white;
$body-color: $white;

// Links
//
// Style anchor elements.

//$link-color: $it-text;
$link-decoration: none;
//$link-hover-color: darken($it-main-orange, 5%);
$link-hover-decoration: none;

// Components
//
// Define common padding and border radius sizes and more.

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-mplus2: 'M Plus 2p', $font-family-sans-serif;
$font-family-mplus1: 'M Plus 1p', $font-family-sans-serif;
$font-family-base: $font-family-mplus1;

$arr-font-family: (
  'font-family-roboto': $font-family-mplus1,
);
$arr-font-family: map-merge((), $arr-font-family);

// stylelint-enable value-keyword-case
$spacer: 1rem;
$spacer-px: 1px;
$font-root: 16;
$font-size-root: ($font-root * $spacer-px);
$font-size-base: 1rem;
$font-size-lg: (36 / $font-root * $spacer);
$font-size-sm: (12 / $font-root * $spacer);


$font-title : 16pt;
$font-primary : 14pt;
$font-secondary : 12pt;
$font-tertiary : 11pt;
$font-bar : 10pt;



$btn-h-primary:50px;
$btn-h-secondary:36px;
$btn-h-tertiary:32px;
$input-h:44px;
$radio-size:20px;



$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$arr-font-weight: ();
$arr-font-weight: map-merge(
  (
    'normal': $font-weight-normal,
    'medium': $font-weight-medium,
    'bold': $font-weight-bold,
  ),
  $arr-font-weight
);

$font-weight-base: $font-weight-normal;
$line-height-base: 1.75;

$h1-font-size: (56 / $font-root * $spacer); // 56px
$h2-font-size: (44 / $font-root * $spacer); // 44px
$h3-font-size: (28 / $font-root * $spacer); // 28px
$h4-font-size: (24 / $font-root * $spacer); // 24px
$h5-font-size: (20 / $font-root * $spacer); // 20px
$h6-font-size: (16 / $font-root * $spacer); // 16px

$arr-font-size: ();
$arr-font-size: map-merge(
  (
    0: (
      0 / $font-root * $spacer,
    ),
    10: (
      10 / $font-root * $spacer,
    ),
    11: (
      11 / $font-root * $spacer,
    ),
    12: (
      12 / $font-root * $spacer,
    ),
    13: (
      13 / $font-root * $spacer,
    ),
    14: (
      14 / $font-root * $spacer,
    ),
    16: (
      16 / $font-root * $spacer,
    ),
    18: (
      18 / $font-root * $spacer,
    ),
    20: (
      20 / $font-root * $spacer,
    ),
    24: (
      24 / $font-root * $spacer,
    ),
    28: (
      28 / $font-root * $spacer,
    ),
    30: (
      30 / $font-root * $spacer,
    ),
    32: (
      32 / $font-root * $spacer,
    ),
    36: (
      36 / $font-root * $spacer,
    ),
    40: (
      40 / $font-root * $spacer,
    ),
    44: (
      44 / $font-root * $spacer,
    ),
    48: (
      48 / $font-root * $spacer,
    ),
    50: (
      50 / $font-root * $spacer,
    ),
    56: (
      56 / $font-root * $spacer,
    ),
    60: (
      60 / $font-root * $spacer,
    ),
    80: (
      80 / $font-root * $spacer,
    ),
    160: (
      160 / $font-root * $spacer,
    ),
  ),
  $arr-font-size
);

$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.5;

$arr-line-height: ();
$arr-line-height: map-merge(
  (
    '1': 1,
    'base': $line-height-base,
    '2': 2,
  ),
  $arr-line-height
);

// Size margin, padding

$mp: ();
// stylelint-disable-next-line scss/dollar-variable-default
$mp: map-merge(
  (
    -50: (-50 / $font-root * $spacer),
    -195: (-195 / $font-root * $spacer),
    -15: (-15 / $font-root * $spacer),
    -10: (-10 / $font-root * $spacer),
    -8: (-8 / $font-root * $spacer),
    0: 0,
    1: ($spacer * 0.25),
    2: ($spacer * 0.5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
    8: (8 / $font-root * $spacer),
    10: (10 / $font-root * $spacer),
    12: (12 / $font-root * $spacer),
    14: (14 / $font-root * $spacer),
    15: (15 / $font-root * $spacer),
    16: (16 / $font-root * $spacer),
    18: (18 / $font-root * $spacer),
    20: (20 / $font-root * $spacer),
    24: (24 / $font-root * $spacer),
    25: (25 / $font-root * $spacer),
    30: (30 / $font-root * $spacer),
    35: (35 / $font-root * $spacer),
    40: (40 / $font-root * $spacer),
    45: (45 / $font-root * $spacer),
    50: (50 / $font-root * $spacer),
    60: (60 / $font-root * $spacer),
    70: (70 / $font-root * $spacer),
    80: (80 / $font-root * $spacer),
    90: (90 / $font-root * $spacer),
    100: (100 / $font-root * $spacer),
    105: (105 / $font-root * $spacer),
    110: (110 / $font-root * $spacer),
    120: (120 / $font-root * $spacer),
    150: (150 / $font-root * $spacer),
    160: (160 / $font-root * $spacer),
    180: (180 / $font-root * $spacer)
  ),
  $mp
);

//$border-color: $it-divider;

$border-radius: (4 / $font-root * $spacer);
$border-radius-md: (8 / $font-root * $spacer);
$border-radius-lg: (16 / $font-root * $spacer);
$border-radius-sm: (2 / $font-root * $spacer);

$box-shadow-sm: 0px 4px 10px rgba($black, 0.08);
$box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
  0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.04);
$box-shadow-lg: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
  0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.04);

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px,
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 1400px,
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 30px;
$grid-row-columns: 6;

// Badges

$badge-font-size: (12 / $font-root * $spacer);
$badge-font-weight: $font-weight-medium;
$badge-padding-y: (2 / $font-root * $spacer);
$badge-padding-x: (10 / $font-root * $spacer);
$badge-border-radius: $border-radius-sm;

$badge-pill-padding-x: 0.6em;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius: 10rem;

// Form validation

//$form-feedback-valid-color:         $it-divider;

$form-feedback-icon-valid: '';
$form-feedback-icon-invalid: '';

// FontAwesome

//$fa-font-path: "../fonts/fontawesome/";
$fa-font-size-base: $font-size-base;

//
// Root
//

$salon-divider-2: #465f75;
$salon-bg-3: #191a1e;
$salon-color-03: #21336b;
$salon-color-admin-block-title: #435d9e;

$border-color: $salon-divider-2;
$modal-content-bg: $salon-bg-3;

$border-radius: (4 / $font-root * $spacer);
$border-radius-lg: (10 / $font-root * $spacer);
$border-radius-xl: (20 / $font-root * $spacer);
$border-radius-sm: (2 / $font-root * $spacer);
$border-radius-custom: (8 / $font-root * $spacer);

$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075);
$box-shadow: 2px 2px 10px rgba($black, 0.2);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175);

$text-dark:#000;

$salon-primary: #0ac7cb;
$salon-primary-light: #3bd2d5;
$salon-primary-dark: #09b3b7;

$salon-blue: #2a42b5;
$salon-blue-light: rgb(79, 109, 255);

$salon-text-secondary: #b4bbc2;
$salon-text-third: #9ca5ae;
$salon-text-placeholder: #cdd2d6;

$salon-divider: #2f435b;
$salon-divider-2: #465f75;

$salon-bg-1: #082038;
$salon-bg-2: #1c3146;
$salon-bg-2-transparent: rgba(#1c3146, 0.7);
$salon-bg-3: #2d3c4a;

$header-h: (62 / $font-root * $spacer);
$sidebar-w: (56 / $font-root * $spacer);
$panel-heading: 50px;
