.form-control,
.custom-select {
  box-shadow: none !important;

  &:focus {
    border-color: $border-color;
  }
}

.form-control {
  &::placeholder {
    color: $gray-400;
  }
}

.error-input {
  border: 1px solid red;
}
