.tabs {
  .nav-tabs {
    border-bottom: 0;
    margin: 0 -1px;
    overflow: hidden;
    overflow-x: scroll;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    .nav-item {
      margin-bottom: 0;
    }

    .nav-link {
      color: $white;
      border: 1px solid $salon-dark;
      background-color: rgba($white, 0.1);
      white-space: nowrap;

      @include rem((
        padding: 9px 30px,
      ));

      &:hover {
        background-color: rgba($white, 0.15);
      }

      &.active {
        background-color: rgba($white, 0.3);
        border-bottom-color: transparent;
      }
    }
  }

  .tab-content {
    position: relative;
  }
}
