@media only screen and (max-width: 769px) {
    .input-custom-sm {
        
    }
}

select {
    width: 100% !important;
    option {
   
        font-size: 16px !important;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .option-css {
        width: 100px !important;
    }
}