.option-content h5 {
    font-size: 16px;
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.option-content h4 {
    font-size: 18px;
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.option-select.active {
    background-color: #d9ead3;
}

@media only screen and (max-width: 575.98px) {
    .option-content h5, .option-content h4 {
        font-size: 16px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767.98px) {
    .option-content h5, .option-content h4 {
        font-size: 16px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {

}

@media only screen and (min-width: 1200px) {

}