// Import variables
@import 'variables';

// Immport fonts
@import 'font';

// Import vendors
@import 'vendors/bootstrap-4.5.0/bootstrap';

@import 'vendors/fontawesome-5.13.0/scss/fontawesome';
@import 'vendors/fontawesome-5.13.0/scss/brands';
@import 'vendors/fontawesome-5.13.0/scss/regular';
@import 'vendors/fontawesome-5.13.0/scss/solid';
@import 'vendors/fontawesome-5.13.0/scss/v4-shims';

// Import mixins
@import 'mixins';

// Base
@import 'common/general';
@import 'common/button';
@import 'common/input';

@import 'common/form';
@import 'common/type';
@import 'common/modal';
@import 'common/pagination';
@import 'common/tabs';
@import 'common/table';

// Layouts
@import 'layouts/header';
@import 'layouts/sidebar';
@import 'layouts/main';
@import 'layouts/footer';

// Components: Font icons of website

// Components: Shortcode
@import 'components/search-filter';
@import 'components/utils';
@import 'components/authentication';

// Pages
@import 'pages/inspection-certificate';
@import 'pages/404';

//

@import 'components/tabBar';


