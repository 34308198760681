.btn1 {

  margin-top: 10px;
  font-size: 22px;
  font-weight: 700;

}

.search-row {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.tb-row {
  text-align: center;

}


.list-row th {
  text-align: center;
  // padding: 2vw
}

.btn2 {
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    margin: 0; // Reset margin
  }
}

.fas {
  font-size: 16px;

}

.list-row {
  margin: auto;
}

.tb-row {
  color: black;
  font-size: 16px;
  font-weight: 700;
}

.pagination {
  display: flex;
  align-items: center;
  margin-top: 1vw;
  color: black;


}


.page-info {
  font-size: 1vw;
  color: black;
}

.select-page {
  display: flex;
  justify-content: space-between;
  width: 30vw;
}

.select-page a {
  color: #000;
  font-size: 1.5vw;

  padding: 1vw;
  padding-right: 3vw;
  padding-left: 3vw;

}

.totalpage {
  border: 1px solid black;
}

.select-page button {
  color: #000;
  font-size: 1.5vw;
  width: 5vw;

}

.tb-optionmanage {
  table-layout: fixed;

  th {
    padding: 0.75rem 0 !important;
  }
  td {
    font-size: 16px !important;
  }
}

.num {
  width: 8.3%;
  // font-size: 16px;
}

.num-2 {
  width: 16.7%;
}



.list-it {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#updatestore {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.btnx-success, .btnx-cancel {
  margin-top: 0 !important;
  height: 42px !important;
  max-width: 100px !important;
  font-size: 22px !important;
  font-weight: 700;
}

.salon-management {
  table {
    td {
      font-size: 16px;
      font-weight: 700;
    }
  }
}
@media only screen and (min-width: 1200px) {
  .table-account-management {
    width: 1200px;
  }
}


@media only screen and (max-width: 769px) {
  .tb-row {
    color: black;
    font-size: 1.5vw;

  }
  .btnx-cancel {
    margin-top: 0 !important;
    height: 42px !important;

  }

  .tb-optionmanage {
    table-layout: fixed;
  
    
    td {
      font-size: 2.5vw !important;
    }
  }

  .search-row {
    width: 100% !important;

    input {
      width: 80% !important;
    }
    .btn-search {
      width: 10% !important;
      min-width: 42px;
    }
  }

  .update-form {
    width: 100% !important;
    textarea {
      width: 100% !important;
    }
    input {
      width: 100% !important;
      height: 42px;
    }
  }
  .row-btn {
    width: 100% !important;
    padding: 0 1.25rem;
    button {
      width: 10%;
      min-width: 100px;
    }
  }

  .content-main {
    .delete-title {
      min-width: 145px !important;
    }
  }

  
}