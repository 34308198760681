.seat-view{
    width: 50vw;
}
.content-main2{
    margin-top:20px;
    color: black;
    font-size: 1.5vw;
    margin-bottom:20px;
   
    // background-color: #f1f1f1;
    padding: 0;
    width: 100%;
}
.content-main2 p{
    font-size: 1.5vw;
}
.gray-bg{
     background-color: #f1f1f1;
     width: 100%;
    padding-left: 10px;
    margin-bottom: 10px;
}
.btn-cf{
    width: 150px;
    height: 45px;
    font-size: 14px;
    margin-top: 20px;
    font-size: 22px;
    font-weight: bold;
    user-select: none; /* Standard syntax */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    
}
.btn-cancel{
    user-select: none; /* Standard syntax */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    
    border-radius: 5px;
    
    font-weight: bold;
    font-size: 14px;
    margin-top: 20px;
    float: left;    
}
.btn-cfcancel{
    user-select: none; /* Standard syntax */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    font-size: 2vw;
    margin-top: 2vw;
    padding-right: 2vw;
    padding-left: 2vw;
    float: left;
}
@media (max-width: 768px) {
    .btn-cf{
        width: 25vw;
        height: 8vw;
        font-size: 2.5vw;
        font-weight: bold;
       
    }
    .btn-cancel{
        
        
        font-size: 2.5vw;
        font-weight: bold;
    }
}