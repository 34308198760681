.row-btn {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-bottom: 20px;
}



// .btn{
//     width: 20vw;
//     font-size: 2vw;
// }
.update-form {
    margin-bottom: 20px;

    textarea {
        width: 56vw;
        max-width: 700px;

        min-height: 42px;
    }
}

.update-form input {
    width: 56vw;
    max-width: 700px;
    max-height: 60px;
    min-height: 42px;
    height: 42px;
    margin-bottom: 20px;
}

.select-name {
    width: 56vw;
    max-width: 700px;
    max-height: 60px;
    min-height: 42px;
    height: 42px;
    margin-bottom: 20px;
}

.error-red {
    color: red;
}

.error-green {
    color: green;
}