.center-vertical {
  margin: 0;
  position: absolute;
  top: 50%;
  // left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.salon-caution-plate {
  &__image {
    @include rem(
      (
        padding: 1.5px,
      )
    );
  }
}

.cursor-pointer {
  cursor: pointer;
}

.bg-gray {
  background-color: #1a1a1a;
}

.br-0 {
  border-radius: 0px;
}

.custom-control-label::before {
  background: #5f5f5f;
  border-color: #5f5f5f;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #5f5f5f;
  color: #c2c2c2;
  border-color: #5f5f5f;
}
// .custom-control-input:checked ~ .custom-control-label::before {
//   color: #c2c2c2;
//   background-color: #b1adad;
//   border-color: #5f5f5f;
// }

.col-1_5 {
  flex: 0 0 12.5%;
  max-width: 12.5%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.table--custom th,
.table--custom td {
  padding: 0.175rem 0.25rem;
  // padding-left: 2px;
  // padding-right: 1px;
}

.p-6 {
  padding: 2rem !important;
}
