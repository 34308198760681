// @ Font-face
$mplus2p-font-path: "/static/fonts/mplus-2p";
$mplus1p-font-path: "/static/fonts/mplus-1p";

// Font-size 100 / Thin
@font-face {
  font-family: 'M Plus 1p';
  font-style: normal;
  font-weight: 400;
  src:  url('#{$mplus1p-font-path}/rounded-mplus-1p-regular.ttf') format('ttf');
}
@font-face {
  font-family: 'M Plus 2p';
  font-style: normal;
  font-weight: 100;
  src: url('#{$mplus2p-font-path}/woff/mplus-2p-thin.woff') format('woff'),
  url('#{$mplus2p-font-path}/tff/mplus-2p-thin.ttf') format('ttf');
}

// Font-size 300 / Light
@font-face {
  font-family: 'M Plus 2p';
  font-style: normal;
  font-weight: 300;
  src: url('#{$mplus2p-font-path}/woff/mplus-2p-light.woff') format('woff'),
  url('#{$mplus2p-font-path}/tff/mplus-2p-light.ttf') format('ttf');
}

// Font-size 400 / Regular
@font-face {
  font-family: 'M Plus 2p';
  font-style: normal;
  font-weight: 400;
  src: url('#{$mplus2p-font-path}/woff/mplus-2p-regular.woff') format('woff'),
  url('#{$mplus2p-font-path}/tff/mplus-2p-regular.ttf') format('ttf');
}

// Font-size 500 / Medium
@font-face {
  font-family: 'M Plus 2p';
  font-style: normal;
  font-weight: 500;
  src: url('#{$mplus2p-font-path}/woff/mplus-2p-medium.woff') format('woff'),
  url('#{$mplus2p-font-path}/tff/mplus-2p-medium.ttf') format('ttf');
}

// Font-size 700 / Bold
@font-face {
  font-family: 'M Plus 2p';
  font-style: normal;
  font-weight: 700;
  src: url('#{$mplus2p-font-path}/woff/mplus-2p-bold.woff') format('woff'),
  url('#{$mplus2p-font-path}/tff/mplus-2p-bold.ttf') format('ttf');
}

// Font-size 800 / Heavy
@font-face {
  font-family: 'M Plus 2p';
  font-style: normal;
  font-weight: 800;
  src: url('#{$mplus2p-font-path}/woff/mplus-2p-heavy.woff') format('woff'),
  url('#{$mplus2p-font-path}/tff/mplus-2p-heavy.ttf') format('ttf');
}

// Font-size 900 / Bold
@font-face {
  font-family: 'M Plus 2p';
  font-style: normal;
  font-weight: 900;
  src: url('#{$mplus2p-font-path}/woff/mplus-2p-black.woff') format('woff'),
  url('#{$mplus2p-font-path}/tff/mplus-2p-black.ttf') format('ttf');
}
