@media (max-width: 769px) {

    .store-menu {
        button {
            width: 100% !important;
        }
    }
    

}

@media (max-width: 400px) {

    .title-store-menu {
        font-size: 2rem;
    }
    

}