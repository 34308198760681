
@import '../variables';
.shift-management-container table td {
    font-size: 16px;
    font-weight: bold;
}

.shift-management-container table th {
    width: 50%;
}

.table-shift-pc {
    max-width: 300px;
}

@media only screen and (max-width: 769px) {
    .select-all-slot {
        justify-content: center !important;
        width: 100% !important;
        // padding: 0 !important;

        label,button {
            // font-size: $font-tertiary !important;
        }

        small {
            line-height: 1.3;
        }
       
    }
    .table-shift-pc {
        max-width: 700px;
    }
}