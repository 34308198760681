@import '../variables';

.container-form_information {
    h1,h2 {
        font-size: $font-title;
    }
    h4,h5 {
        font-size: $font-title;
    }
    button {
        height: $btn-h-primary !important;
    }
    .form-information {
        label {
            font-size:$font-primary;
        }
        .form-input {
            height: $input-h;
            border: 1px solid black;
        }

        .radio-buttons-gender {

            .radio-gender {
                height: $radio-size;
                width: $radio-size;
                border: black;
            }

            .name-gender {
                font-size: $font-primary;
            }
        }
        #area-infor {
            border: 1px solid ;
        }
        
    }

    
}
.span-req {
    font-size: 13px !important;
    display: flex;
    background-color: #888484;
    color: #fff;
    font-weight: 400 !important;
    // margin-top: 0.3rem;
    margin: 2px 0 0px 5px;
    padding: 0px 8px 0px 8px;
    border-radius: 6px;
    max-height: 22px;
    align-items: center;
    justify-content: center;
}
.span-req.req {
   
    background-color: rgb(243, 84, 84) ;
    
}
@media (max-width: 769px) {
    
    .container-form_information {
        width: 100% !important;
        h1,h2{
            font-size: 16px !important;
        }
        h4,h5,p,label {
            font-size: 14px !important;
        }
        .radio-buttons-gender {
            justify-content: flex-start !important;
       

        }

        .form-select {
            width: 40% !important;
            height: $input-h;
            border: 1px solid;
        }

        #area-infor {
            width: 100% !important;
        }
    }

}