.logo {
  img {
    max-height: 40px;
  }
}

.fse-14 {
  font-size: 0.875em;
  // font-size: 14px;
}
.fs-16 {
  font-size: 16px;
  // font-size: 14px;
}

.mre-20 {
  margin-right: 1.25em;
  // margin-right: 20px;
}
